// app/javascript/controllers/single_youtube_player_controller.js
import BaseController from "./utils/base_controller"

export default class extends BaseController {
    static targets = ["minutes", "seconds"]
    static values = {
        loadPath: String,
        savePath: String,
        atomId: String,
        atomType: String,
        youtubeIndex: Number
    }

    async connect() {
        this.debugLog('single_youtube_player connecting')
        super.connect()
        await this.loadSettings()
    }

    async loadSettings() {
        this.debugLog('loadSettings')
        if (this.hasLoadPathValue) {
            try {
                const response = await fetch(this.loadPathValue)
                if (response.ok) {
                    const settings = await response.json()
                    if (settings && settings.youtube_urls) {
                        const urlData = settings.youtube_urls[this.youtubeIndexValue]
                        if (urlData) {
                            this.minutesTarget.value = urlData.start_minutes
                            this.secondsTarget.value = urlData.start_seconds
                        }
                    }
                }
            } catch (error) {
                this.log('Error loading YouTube settings:', error)
            }
        }
    }

    async updateStartTime(event) {
        const minutes = this.minutesTarget.value
        const seconds = this.secondsTarget.value

        if (this.hasSavePathValue) {
            try {
                const response = await fetch(this.savePathValue, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
                    },
                    body: JSON.stringify({
                        update_type: 'start_time',
                        url_index: this.youtubeIndexValue,
                        start_minutes: minutes,
                        start_seconds: seconds
                    })
                })

                if (!response.ok) throw new Error('Failed to save settings')
            } catch (error) {
                this.log('Error saving YouTube settings:', error)
            }
        }
    }
}