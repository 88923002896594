import { Controller } from "@hotwired/stimulus"

/**
 * Mini SoundfilePlayer Controller
 * A simplified version of the SoundfilePlayer for space-constrained contexts
 * Focuses on playback only with minimal controls and loads but doesn't save settings
 */
export default class SoundfilePlayerMiniController extends Controller {
    static targets = ["player"]

    static values = {
        playerClass: String,
        debug: { type: Boolean, default: false },
        loadPath: String
    }

    connect() {
        this.debugLog("Mini SoundfilePlayer Controller Connected")
        this.setupAudio()
        this.setupPlyr()
        this.loadSettings()
    }

    disconnect() {
        if (this.player) {
            this.player.destroy()
        }
        if (this.audio) {
            this.audio.removeEventListener('timeupdate', this.boundCheckLoopTime)
        }
    }

    setupAudio() {
        this.audio = document.getElementById(this.playerClassValue)
        if (!this.audio) {
            this.debugLog(`Audio element with id '${this.playerClassValue}' not found`, "warn")
        }
    }

    setupPlyr() {
        if (this.hasPlayerTarget) {
            try {
                this.player = new Plyr(this.playerTarget, this.plyrOptions)
                this.player.on('ended', this.onPlayerEnded.bind(this))
                this.debugLog("Plyr initialized successfully")
            } catch (error) {
                this.debugLog(`Error initializing Plyr: ${error}`, "error")
            }
        }
    }

    async loadSettings() {
        if (!this.player || !this.hasLoadPathValue) return

        try {
            const response = await fetch(this.loadPathValue)
            if (!response.ok) return

            const settings = await response.json()
            const settingsData = settings.playback_settings || settings

            // Load volume
            if (settingsData?.volume != null) {
                this.player.volume = Math.min(1, Math.max(0, settingsData.volume))
            }

            // Load speed if set
            if (settingsData?.speed != null) {
                this.player.speed = Math.min(2, Math.max(0.5, settingsData.speed))
            }

            // Handle loop settings if they exist
            if (this.audio && settingsData?.loop_enabled) {
                // Wait for audio metadata if needed
                if (!this.audio.duration) {
                    await new Promise(resolve => {
                        this.audio.addEventListener('loadedmetadata', resolve, { once: true })
                    })
                }

                this.audio.loop = true
                this.loopEnabled = true
                this.loopStart = settingsData.loop_start || 0
                this.loopEnd = settingsData.loop_end || this.audio.duration

                // Set up loop checking for custom loop points
                this.boundCheckLoopTime = this.checkLoopTime.bind(this)
                this.audio.addEventListener('timeupdate', this.boundCheckLoopTime)

                // Set initial position
                this.audio.currentTime = this.loopStart
            }

            this.debugLog('Settings loaded successfully')
        } catch (error) {
            this.debugLog('Error loading settings:', error)
        }
    }

    checkLoopTime() {
        if (this.audio && this.loopEnabled && !this.audio.paused) {
            if (this.audio.currentTime >= this.loopEnd) {
                this.audio.currentTime = this.loopStart
            }
        }
    }

    onPlayerEnded() {
        if (this.loopEnabled && this.player) {
            this.player.currentTime = this.loopStart
            this.player.play()
        }
    }

    get plyrOptions() {
        return {
            preload: 'metadata',
            controls: [
                'restart', 'play', 'volume', 'mute', 'settings'
            ],
            speed: {
                selected: 1,
                options: [0.7, 0.8, 0.9, 1, 1.1, 1.2]
            },
            volume: 0.6
        }
    }

    debugLog(message, level = 'debug') {
        if (this.debugValue) {
            console[level](`Mini SoundfilePlayer: ${message}`)
        }
    }
}