import { Controller } from "@hotwired/stimulus"

import BaseController from "../utils/base_controller";

export default class extends Controller {
    static values = {
        url: String
    }

    connect() {
        console.log("urlValue:", this.urlValue)
    }


    async remove(event) {
        event.preventDefault()
        console.log("urlValue:", this.urlValue)
        try {
            const response = await fetch(this.urlValue, {
                method: 'PATCH',
                headers: {
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
                    'Accept': 'application/json'
                }
            })

            if (response.ok) {
                this.element.classList.add('opacity-0', 'scale-95', 'translate-x-[-100%]')
                setTimeout(() => {
                    this.element.remove()
                }, 500)
            }
        } catch (error) {
            console.error('Failed to unschedule:', error)
        }
    }
}