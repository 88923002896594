import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["modal", "backdrop", "content", "refreshButton", "loadingOverlay", "openButton"]
    static classes = ["active", "inactive"]

    connect() {
        this.boundHideOnEscape = this.hideOnEscape.bind(this)
        document.addEventListener('keydown', this.boundHideOnEscape)
    }

    disconnect() {
        document.removeEventListener('keydown', this.boundHideOnEscape)
    }

    hideOnEscape(event) {
        if (event.key === "Escape") {
            this.hide()
        }
    }

    show() {
        this.modalTarget.classList.remove(this.inactiveClass)
        this.modalTarget.classList.add(this.activeClass)
        this.backdropTarget.classList.remove(this.inactiveClass)
        this.backdropTarget.classList.add(this.activeClass)
        document.body.classList.add('overflow-hidden')
        this.loadCalendar()
    }

    hide() {
        this.modalTarget.classList.remove(this.activeClass)
        this.modalTarget.classList.add(this.inactiveClass)
        this.backdropTarget.classList.remove(this.activeClass)
        this.backdropTarget.classList.add(this.inactiveClass)
        document.body.classList.remove('overflow-hidden')
    }

    async loadCalendar() {
        if (!this.hasContentTarget) return
        this.showLoading()

        try {
            const response = await fetch('/practice_calendar', {
                headers: {
                    'Accept': 'text/html',
                    'X-Requested-With': 'XMLHttpRequest',
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
                },
                credentials: 'same-origin'
            })

            if (!response.ok) throw new Error('Network response was not ok')
            const html = await response.text()
            this.contentTarget.innerHTML = html
        } catch (error) {
            console.error('Error loading calendar:', error)
        } finally {
            this.hideLoading()
        }
    }

    async refresh() {
        if (!this.hasContentTarget) return
        if (this.hasRefreshButtonTarget) {
            this.refreshButtonTarget.disabled = true
        }
        this.showLoading()

        try {
            const response = await fetch('/practice_calendar/refresh', {
                method: 'POST',
                headers: {
                    'Accept': 'text/html',
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
                    'X-Requested-With': 'XMLHttpRequest'
                },
                credentials: 'same-origin'
            })

            if (!response.ok) throw new Error('Network response was not ok')
            const html = await response.text()
            this.contentTarget.innerHTML = html
        } catch (error) {
            console.error('Error refreshing calendar:', error)
        } finally {
            if (this.hasRefreshButtonTarget) {
                this.refreshButtonTarget.disabled = false
            }
            this.hideLoading()
        }
    }

    showLoading() {
        if (!this.hasLoadingOverlayTarget) return
        this.loadingOverlayTarget.classList.remove('hidden')
    }

    hideLoading() {
        if (!this.hasLoadingOverlayTarget) return
        this.loadingOverlayTarget.classList.add('hidden')
    }
}