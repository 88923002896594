import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["editor"]
    static values = {
        loadPath: String,
        savePath: String,
        delay: { type: Number, default: 200 }
    }

    connect() {
        this.saveContent = this.debounce(this.saveContent.bind(this), this.delayValue)
        this.load()
        this.editorTarget.addEventListener("input", () => this.saveContent())
    }

    disconnect() {
        this.editorTarget.removeEventListener("input", () => this.saveContent())
    }

    async load() {
        try {
            const response = await fetch(this.loadPathValue)
            const data = await response.json()
            this.editorTarget.value = data.notes
        } catch (error) {
            console.error("Error loading notes:", error)
        }
    }

    async saveContent() {
        const formData = new FormData()
        formData.append('notes', this.editorTarget.value)

        fetch(this.savePathValue, {
            method: 'PATCH',
            body: formData,
            headers: {
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            },
            credentials: 'same-origin'
        })
            .catch(error => console.error('Error:', error))
    }

    // Simple debounce implementation without lodash dependency
    debounce(func, wait) {
        let timeout
        return function executedFunction(...args) {
            const later = () => {
                clearTimeout(timeout)
                func(...args)
            }
            clearTimeout(timeout)
            timeout = setTimeout(later, wait)
        }
    }
}