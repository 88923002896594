import BaseController from "../utils/base_controller"

export default class extends BaseController {
    static targets = [
        "container",
        "notes",
        "video",
        "metronome",
        "timer",
        "notesButton",
        "videoButton",
        "metronomeButton",
        "timerButton",
        "sideBar",
        "image"
    ]

    static values = {
        loadPath: String,
        savePath: String,
        atomId: String
    }

    async connect() {
        super.connect()
        this.hideAll()
        await this.loadSettings()
    }

    hasSidebarButtons() {
        return this.hasNotesButtonTarget &&
            this.hasVideoButtonTarget &&
            this.hasMetronomeButtonTarget &&
            this.hasTimerButtonTarget;
    }

    async loadSettings() {
        if (this.hasLoadPathValue) {
            try {
                const response = await fetch(this.loadPathValue)
                if (response.ok) {
                    const settings = await response.json()
                    if (settings && typeof settings === 'object') {
                        this.applySettings(settings)
                        return
                    }
                }
            } catch (error) {
                this.log('Error loading API settings:', error)
            }
        }

        // Fallback to localStorage if API fails or isn't configured
        try {
            const savedSettings = JSON.parse(localStorage.getItem('sidebarSettings') || '{}')
            if (Object.keys(savedSettings).length) {
                this.applySettings(savedSettings)
            }
        } catch (error) {
            this.log('Error loading local storage settings:', error)
        }
    }

    applySettings(settings) {
        if (!settings || typeof settings !== 'object') {
            this.log('Invalid settings object received:', settings)
            settings = {}
        }

        const activeSection = settings.activeSection
        if (activeSection && this.hasContainerTarget) {
            this.hideAll()
            this.containerTarget.classList.add(`${activeSection}-visible`)

            // Add a small delay to ensure DOM is updated
            requestAnimationFrame(() => {
                this.updateButtonStates(activeSection)
                this.updateContainerVisibility()
            })
        }
    }

    async saveSettings() {
        const activeSection = ["notes", "video", "metronome", "timer"].find(section =>
            this.containerTarget.classList.contains(`${section}-visible`)
        ) || null

        const settings = {
            date: new Date().toDateString(),
            activeSection: activeSection
        }

        if (this.hasSavePathValue) {
            try {
                const response = await fetch(this.savePathValue, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
                    },
                    body: JSON.stringify({ sidebar_settings: settings })
                })

                if (!response.ok) throw new Error('Failed to save settings')
            } catch (error) {
                this.log('Error saving API settings:', error)
                localStorage.setItem('sidebarSettings', JSON.stringify(settings))
            }
        } else {
            localStorage.setItem('sidebarSettings', JSON.stringify(settings))
        }
    }

    toggleNotes(event) {
        event.preventDefault()
        this.toggleSection("notes")
    }

    toggleVideo(event) {
        event.preventDefault()
        this.toggleSection("video")
    }

    toggleMetronome(event) {
        event.preventDefault()
        this.toggleSection("metronome")
    }

    toggleTimer(event) {
        event.preventDefault()
        this.toggleSection("timer")
    }

    async toggleSection(sectionName) {
        if (!this.hasContainerTarget) {
            this.log(`Cannot toggle section: container not found`, "warn")
            return
        }

        if (!sectionName) {
            this.log(`No section name provided`, "warn")
            return
        }

        const isVisible = this.containerTarget.classList.contains(`${sectionName}-visible`)

        // First update the button states based on what we're going to do
        this.updateButtonStates(isVisible ? null : sectionName)

        // Then update the visibility
        this.hideAll()
        if (!isVisible) {
            this.containerTarget.classList.add(`${sectionName}-visible`)
        }

        this.updateContainerVisibility()
        await this.saveSettings()
    }

    hideAll() {
        if (!this.hasContainerTarget) return
        this.containerTarget.classList.remove(
            "notes-visible",
            "video-visible",
            "metronome-visible",
            "timer-visible"
        )
    }

    updateButtonStates(activeView) {
        if (!this.hasSidebarButtons()) {
            console.warn('Sidebar buttons not all available');
            return;
        }

        const buttons = {
            notes: this.notesButtonTarget,
            video: this.videoButtonTarget,
            metronome: this.metronomeButtonTarget,
            timer: this.timerButtonTarget
        }

        Object.entries(buttons).forEach(([view, button]) => {
            if (button && button.dataset) {
                button.dataset.state = view === activeView ? "active" : "inactive"
            }
        })
    }

    updateContainerVisibility() {
        if (!this.hasContainerTarget) return

        const hasVisibleContent = ["notes", "video", "metronome", "timer"].some(section =>
            this.containerTarget.classList.contains(`${section}-visible`)
        )

        this.containerTarget.classList.toggle("no-content-visible", !hasVisibleContent)
    }
}