// app/javascript/controllers/multiple_youtube_urls_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["template", "urlsContainer", "urlField", "addButton"]
    static values = { maxCount: { type: Number, default: 5 } }

    connect() {
        this.updateAddButtonVisibility()
    }

    addField(event) {
        event.preventDefault()
        const content = this.templateTarget.content.cloneNode(true)
        this.urlsContainerTarget.appendChild(content)
        this.updateAddButtonVisibility()
    }

    removeField(event) {
        event.preventDefault()
        const field = event.currentTarget.closest('[data-multiple-youtube-urls-target="urlField"]')
        if (field) {
            if (this.urlFieldTargets.length > 1) {
                field.remove()
                this.updateAddButtonVisibility()
            } else {
                // If it's the last field, just clear it
                field.querySelector('input').value = ''
            }
        }
    }

    updateAddButtonVisibility() {
        if (this.hasAddButtonTarget) {
            this.addButtonTarget.classList.toggle('hidden', this.urlFieldTargets.length >= this.maxCountValue)
        }
    }
}