// app/javascript/controllers/audio_recorder_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "recordButton",
        "recordIcon",
        "playButton",
        "playIcon",
        "audioPlayer",
        "volumeSlider",
        "progressSlider",
        "currentTime"
    ]

    connect() {
        this.mediaRecorder = null
        this.audioChunks = []
        this.isRecording = false
        this.isPlaying = false
        this.audioStream = null
        this.isUserSeeking = false

        if (this.hasAudioPlayerTarget) {
            this.audioPlayerTarget.volume = 0.7
            this.audioPlayerTarget.addEventListener('timeupdate', this.updateProgress.bind(this))
            this.audioPlayerTarget.addEventListener('ended', () => {
                this.resetProgress()
                this.isPlaying = false
                this.updatePlayButton(false)
            })
        }
    }

    getMimeType() {
        const types = [
            'audio/webm;codecs=opus',
            'audio/webm',
            'audio/mp4',
            'audio/ogg;codecs=opus'
        ]
        return types.find(type => MediaRecorder.isTypeSupported(type)) || ''
    }

    disconnect() {
        this.cleanupAudioResources()
    }

    cleanupAudioResources() {
        if (this.audioStream) {
            this.audioStream.getTracks().forEach(track => track.stop())
            this.audioStream = null
        }
        if (this.mediaRecorder) {
            this.mediaRecorder = null
        }
        this.audioChunks = []
    }

    formatTime(seconds) {
        if (!seconds || isNaN(seconds)) return "0:00"
        const minutes = Math.floor(seconds / 60)
        const remainingSeconds = Math.floor(seconds % 60)
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`
    }

    startSeeking() {
        this.isUserSeeking = true
    }

    endSeeking() {
        this.isUserSeeking = false
        this.seek()
    }

    updateProgress() {
        if (!this.isUserSeeking && this.audioPlayerTarget.readyState > 0) {
            const currentTime = this.audioPlayerTarget.currentTime || 0
            const duration = this.recordingDuration || this.audioPlayerTarget.duration

            if (duration && isFinite(duration) && duration > 0) {
                const percentage = (currentTime / duration) * 100
                this.progressSliderTarget.value = percentage.toString()
                this.currentTimeTarget.textContent = this.formatTime(currentTime)
            }
        }
    }

    resetProgress() {
        this.progressSliderTarget.value = "0"
        this.currentTimeTarget.textContent = '0:00'
    }

    seek() {
        const duration = this.recordingDuration || this.audioPlayerTarget.duration
        if (duration && isFinite(duration)) {
            const percentage = parseFloat(this.progressSliderTarget.value)
            const time = (percentage / 100) * duration
            try {
                this.audioPlayerTarget.currentTime = time
                this.currentTimeTarget.textContent = this.formatTime(time)
            } catch (error) {
                console.error("Seeking failed:", error)
            }
        }
    }
    updateVolume() {
        const volume = this.volumeSliderTarget.value
        this.audioPlayerTarget.volume = volume
    }

    async toggleRecording(event) {
        event.preventDefault()

        if (!this.isRecording) {
            await this.startRecording()
        } else {
            this.stopRecording()
        }
    }

    async startRecording() {
        try {
            this.cleanupAudioResources()
            this.audioChunks = []

            this.recordingTimeout = setTimeout(() => {
                if (this.isRecording) {
                    this.stopRecording()
                }
            }, 5 * 60 * 1000)

            this.audioStream = await navigator.mediaDevices.getUserMedia({
                audio: {
                    channelCount: 1,
                    echoCancellation: false,
                    noiseSuppression: false,
                    autoGainControl: false,
                    sampleRate: 44100,
                    latencyHint: 'interactive'
                }
            })

            this.recorderContext = new (window.AudioContext || window.webkitAudioContext)({
                sampleRate: 44100
            })

            if (this.recorderContext.state === 'suspended') {
                await this.recorderContext.resume()
            }

            this.audioSource = this.recorderContext.createMediaStreamSource(this.audioStream)

            this.gainNode = this.recorderContext.createGain()
            this.gainNode.gain.value = 4

            this.channelMerger = this.recorderContext.createChannelMerger(2)

            this.audioSource.connect(this.gainNode)
            this.gainNode.connect(this.channelMerger, 0, 0)
            this.gainNode.connect(this.channelMerger, 0, 1)

            this.recorderDestination = this.recorderContext.createMediaStreamDestination()
            this.channelMerger.connect(this.recorderDestination)

            const mimeType = this.getMimeType()
            this.recorderOptions = {
                audioBitsPerSecond: 256000,
                mimeType,
                sampleRate: 44100
            }

            this.mediaRecorder = new MediaRecorder(this.recorderDestination.stream, this.recorderOptions)

            this.mediaRecorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    this.audioChunks.push(event.data)
                }
            }

            this.mediaRecorder.onstop = async () => {
                console.log("Recording stopped, creating blob...")
                const audioBlob = new Blob(this.audioChunks, {
                    type: mimeType || 'audio/webm'
                })

                // Calculate duration from the audio chunks
                const arrayBuffer = await audioBlob.arrayBuffer()
                const audioContext = new (window.AudioContext || window.webkitAudioContext)()
                const audioBuffer = await audioContext.decodeAudioData(arrayBuffer)
                const duration = audioBuffer.duration

                if (this.audioPlayerTarget.src) {
                    URL.revokeObjectURL(this.audioPlayerTarget.src)
                }

                const audioUrl = URL.createObjectURL(audioBlob)
                this.audioPlayerTarget.src = audioUrl

                // Store the duration we calculated
                this.recordingDuration = duration
                console.log("Calculated duration:", duration)

                this.audioPlayerTarget.load()
            }

            this.mediaRecorder.start(250)
            this.isRecording = true
            this.recordButtonTarget.classList.remove('recording-pulse')
            this.recordButtonTarget.classList.add('recording-pulse')

            this.audioPlayerTarget.pause()
            this.audioPlayerTarget.currentTime = 0
            this.isPlaying = false
            this.updatePlayButton(false)
            this.resetProgress()

        } catch (error) {
            this.cleanupAudioResources()
        }
    }

    stopRecording() {
        if (this.mediaRecorder && this.mediaRecorder.state !== 'inactive') {
            if (this.recordingTimeout) {
                clearTimeout(this.recordingTimeout)
                this.recordingTimeout = null
            }

            this.mediaRecorder.stop()
            this.isRecording = false
            this.recordButtonTarget.classList.remove('recording-pulse')

            if (this.audioStream) {
                this.audioStream.getTracks().forEach(track => track.stop())
            }
        }
    }

    togglePlayback(event) {
        event.preventDefault()

        if (!this.audioPlayerTarget.src) {
            return
        }

        console.log("Current readyState:", this.audioPlayerTarget.readyState)
        console.log("Current duration:", this.audioPlayerTarget.duration)

        if (this.isPlaying) {
            this.audioPlayerTarget.pause()
            this.isPlaying = false
            this.updatePlayButton(false)
        } else {
            if (this.isRecording) {
                this.stopRecording()
            }

            // Force a load if not ready
            if (this.audioPlayerTarget.readyState < 2) {
                console.log("Forcing audio load...")
                this.audioPlayerTarget.load()
            }

            const playPromise = this.audioPlayerTarget.play()
            if (playPromise !== undefined) {
                playPromise.then(() => {
                    console.log("Playback started successfully")
                    this.isPlaying = true
                    this.updatePlayButton(true)
                    this.updateProgress()
                }).catch(error => {
                    console.error("Playback failed:", error)
                })
            }
        }
    }

    updatePlayButton(isPlaying) {
        const playButton = this.playButtonTarget
        const playIcon = this.playIconTarget

        if (isPlaying) {
            playIcon.innerHTML = `
  <div class="w-4 h-4 flex justify-between">
    <div class="w-1.5 h-full bg-white"></div>
    <div class="w-1.5 h-full bg-white"></div>
  </div>
`
            playButton.classList.add('bg-blue-700')
        } else {
            playIcon.innerHTML = `
        <div class="play-icon w-0 h-0 border-t-[8px] border-t-transparent border-l-[16px] border-l-white border-b-[8px] border-b-transparent ml-1"></div>
      `
            playButton.classList.remove('bg-blue-700')
        }
    }
}