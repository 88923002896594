import BaseController from "./utils/base_controller"

export default class extends BaseController {
    static targets = [
        "player",
        "button",
        "minutes",
        "seconds"
    ]

    static values = {
        loadPath: String,
        savePath: String,
        atomId: String,
        atomType: String
    }

    async connect() {
        this.debugLog('multi_youtube_player connecting')
        super.connect()
        await this.loadSettings()
    }

    async loadSettings() {
        if (this.hasLoadPathValue) {
            try {
                const response = await fetch(this.loadPathValue)
                if (response.ok) {
                    const settings = await response.json()
                    if (settings && typeof settings === 'object') {
                        this.applySettings(settings)
                        return
                    }
                }
            } catch (error) {
                this.log('Error loading YouTube settings:', error)
            }
        }
    }

    applySettings(settings) {
        if (!settings || typeof settings !== 'object') {
            this.log('Invalid settings object received:', settings)
            return
        }

        // Apply active video index
        const activeIndex = settings.active_video_index || 0
        this.playerTargets.forEach((player, index) => {
            player.classList.toggle("hidden", index !== activeIndex)
        })

        // Update button states
        this.buttonTargets.forEach((button, index) => {
            button.dataset.state = index === activeIndex ? "active" : "inactive"
        })

        // Apply start times if present
        if (settings.youtube_urls) {
            settings.youtube_urls.forEach((urlData, index) => {
                const minutes = this.minutesTargets[index]
                const seconds = this.secondsTargets[index]
                if (minutes) minutes.value = urlData.start_minutes
                if (seconds) seconds.value = urlData.start_seconds
            })
        }
    }

    async saveSettings(settings) {
        if (this.hasSavePathValue) {
            try {
                const response = await fetch(this.savePathValue, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
                    },
                    body: JSON.stringify({ update_type: settings.update_type, ...settings })
                })

                if (!response.ok) throw new Error('Failed to save settings')
            } catch (error) {
                this.log('Error saving YouTube settings:', error)
            }
        }
    }

    async switchVideo(event) {
        const selectedIndex = parseInt(event.currentTarget.dataset.index)

        // Update UI
        this.playerTargets.forEach((player, index) => {
            player.classList.toggle("hidden", index !== selectedIndex)
        })

        this.buttonTargets.forEach((button, index) => {
            button.dataset.state = index === selectedIndex ? "active" : "inactive"
        })

        // Save active index
        await this.saveSettings({
            update_type: 'active_video',
            active_video_index: selectedIndex
        })
    }

    updateStartTime(event) {
        const target = event.currentTarget
        const playerIndex = target.closest('[data-player-index]').dataset.playerIndex

        const minutes = this.minutesTargets[playerIndex].value
        const seconds = this.secondsTargets[playerIndex].value

        this.saveSettings({
            update_type: 'start_time',
            url_index: playerIndex,
            start_minutes: minutes,
            start_seconds: seconds
        })
    }
}