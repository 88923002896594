import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["button", "badge"]
    static values = {
        url: String
    }

    async schedule(event) {
        event.preventDefault()
        this.quickToggle()

        try {
            const response = await fetch(this.urlValue, {
                method: 'PATCH',
                headers: {
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
                    'Accept': 'application/json'
                }
            })

            if (!response.ok) {
                this.revertToggle()
            }
        } catch (error) {
            console.error('Failed to schedule:', error)
            this.revertToggle()
        }
    }

    quickToggle() {
        this.buttonTarget.style.display = 'none'
        this.badgeTarget.style.display = 'block'
    }

    revertToggle() {
        this.buttonTarget.style.display = 'block'
        this.badgeTarget.style.display = 'none'
    }
}