function cleanupOldPlyrInstances() {
    Array.from(document.querySelectorAll(".plyr")).forEach((playerElement) => {
        try {
            const player = Plyr.getInstance(playerElement);
            if (player && typeof player.destroy === 'function') {
                player.destroy();
            }
        } catch (err) {
            //    todo maybe later
        }
    });
}
//Clean up old players
document.addEventListener('turbo:before-render', () => {
    console.log('cleanupOldPlyrInstances : turbo:before-render');
    cleanupOldPlyrInstances();
});
